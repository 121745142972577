@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Aldrich';
  src: url('./assets/fonts/aldrich/Aldrich-Regular.ttf');
}

@font-face {
  font-family: 'Gill_Sans';
  src: url('./assets/fonts/gill_sans/GILLSANS.ttf');
}

body {
  margin: 0;
  /* font-family: Aldrich, 'Gill_Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', */
  /* 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: Aldrich, 'Gill Sans', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
html,
body,
#root {
  max-height: 100%;
  max-width: 100%;
}

.modal-overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.75);
}

.rs-background {
  position: relative;
  background-image: url('./assets/images/Stars.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.rs-background:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* transform: scale(1); */
  z-index: -2;
}

.corners {
  position: relative;
  /* width: 50px; for demo purposes */
  padding: 10px;
}

/* Rectangle 4 */
.theCorner {
  box-sizing: border-box;

  position: absolute;
  width: 80px;
  height: 80px;
}

.topLeft {
  left: -26px;
  top: -26px;
  transform: rotate(-45deg);
}

/* Rectangle 6 */

.topRight {
  right: -26px;
  top: -26px;
  transform: rotate(45deg);
}

/* Rectangle 5 */
.bottomLeft {
  left: -26px;
  bottom: -26px;
  transform: rotate(-135deg);
}

/* Rectangle 7 */

.bottomRight {
  right: -26px;
  bottom: -26px;
  transform: rotate(135deg);
}

.ReactModalPortal {
  position: absolute;
  z-index: 50;
}

.centre-logo img {
  max-height: 100%;
}
